<template>
    <a-card>
        <a-page-header
            title="投放视频管理"
        />

        <a-form-model
            ref="form"
            :model="form"
            v-bind='layout'
        >
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="广告主">
                        <a-input
                            v-model="form.advertiserName"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="视频标题">
                        <a-input
                            v-model="form.videoTitle"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="视频品牌">
                        <a-select
                            mode="multiple"
                            v-model="form.principalIdList"
                            placeholder="请选择品牌"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                            @change="onChangePrincipal"
                        >
                            <a-select-option
                                v-for="item in principalList"
                                :key="item.id"
                            >
                                {{ item.principal_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="集客场景">
                        <a-select
                            mode="multiple"
                            v-model="form.customerSceneList"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in customerSceneList"
                                :key="item.code"
                            >
                                {{ item.desc }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="视频车系">
                        <a-select
                            mode="multiple"
                            v-model="form.carSeriesIdList"
                            placeholder="请选择车系"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option
                                v-for="item in seriesList"
                                :key="item.id"
                            >
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="状态">
                        <a-select
                            v-model="form.videoStatus"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="1">有效</a-select-option>
                            <a-select-option key="2">停用</a-select-option>
                            <a-select-option key="3">过期</a-select-option>
                            <a-select-option key="4">删除</a-select-option>
                            <a-select-option key="5">未上传</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="点击率">
                        <a-input-number :min="0" v-model="form.clickRateMin"></a-input-number>
                        ~
                        <a-input-number :min="0" v-model="form.clickRateMax"></a-input-number>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="7">
                    <a-form-model-item label="转化率">
                        <a-input-number :min="0" v-model="form.convertRateMin"></a-input-number>
                        ~
                        <a-input-number :min="0" v-model="form.convertRateMax"></a-input-number>
                    </a-form-model-item>
                </a-col>
                <a-col :span="1"></a-col>
                <a-col :span="8">
                    <a-form-model-item label="视频上传方式">
                        <a-select
                            v-model="form.adVideoSource"
                            placeholder="请选择"
                            allow-clear
                            show-search
                            option-filter-prop="children"
                        >
                            <a-select-option key="1">皮皮慧投</a-select-option>
                            <a-select-option key="2">广告平台</a-select-option>
                            <!-- <a-select-option key="3">赠送</a-select-option>
                            <a-select-option key="4">其他</a-select-option> -->
                        </a-select>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="7">
                    <a-form-model-item label="巨量素材ID">
                        <a-input
                            v-model="form.adMaterialId"
                            placeholder="请输入"
                        />
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col span="12">
                    <a-space>
                        <a-button type="primary" @click="batchByAdVideoIds('valid')">批量有效</a-button>
                        <a-button type="primary" @click="batchByAdVideoIds('disable')">批量停用</a-button>
                    </a-space>
                </a-col>
                <a-col span="12" class="flex-end">
                    <a-space>
                        <a-button @click="handleReset">重置</a-button>
                        <a-button type="primary" @click="handleSearch">查询</a-button>
                        <a-button type="primary" @click="editVideo(0)">上传视频</a-button>
                    </a-space>
                </a-col>
            </a-row>
        </a-form-model>

        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :rowKey="record => record.hjkAdVideoId"
            :row-selection="rowSelection"
            :pagination='false'
            :scroll="{x: true}"
            @change="handleTableChange"
        >
            <div
                slot="operation"
                slot-scope="text, record"
            >
                <base-button
                    :type="'link'"
                    :title="'更多信息'"
                    @onClickBtn="jumpDetailPage(record.hjkAdVideoId)"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'赠送'"
                    @onClickBtn="giftVideo(record.hjkAdVideoId)"
                    v-if="record.videoStatus == 1"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'修改'"
                    @onClickBtn="editVideo(record.hjkAdVideoId)"
                    v-if="!record.principalName || !record.carSeriesName || !record.customerSceneDesc"
                ></base-button>
                <base-button
                    :type="'link'"
                    :title="'复制上传'"
                    @onClickBtn="editVideo(record.hjkAdVideoId, 'copy')"
                ></base-button>
            </div>
        </a-table>

        <base-pagination
            :currentPage="pagination.current"
            :pageSize="pagination.pageSize"
            :total="pagination.total"
            @onChange="handlePaginationChange"
            @onShowSizeChange="handlePaginationChange"
        />

        <div class="mt-40">
            <div>PS：</div>
            <div>1、累计播放量、点击率、转化率统计数截止昨日</div>
            <div>2、新建广告计划时，相同车系和集客场景的有效的视频≥10条，优先选择项目关联的，其次选择广告平台创建时间最新的10条</div>
            <div><span class="red">（暂未上线）</span>3、系统<span class="red">实时删除</span>由有效→停用/过期/删除状态视频关联的广告创意（实际删除时间预计在30分钟内）</div>
        </div>

        <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handlePreviewCancel"
            :zIndex="10001"
        >
            <video
                :src="previewSrc"
                controls
                style="height: 480px; width: 100%"
            >
                你的浏览器不支持video
            </video>
        </a-modal>
    </a-card>
</template>

<script>
import previewModal from "@/components/PreviewModalVideo/PreviewModalVideo";
import utils from "@/utils/FormatUtil.js";

export default {
    components: {
        previewModal
    },
    data() {
        return {
            layout: {
                labelCol: { span: 8 },
                wrapperCol: { span: 16 }
            },
            form: {
                videoStatus: '1'
            },
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '状态',
                    fixed: 'left',
                    customRender:(text, row) => {
                        let txt = '-'
                        switch(row.videoStatus) {
                            case 1:
                                txt = '有效'
                                break;
                            case 2:
                                txt = <div class="red">停用</div>
                                break;
                            case 3:
                                txt = <div class="red">过期</div>
                                break;
                            case 4:
                                txt = <div class="red">删除</div>
                                break;
                            case 5:
                                txt = '未上传'
                                break;
                        }

                        return txt
                    }
                },
                {
                    align: 'center',
                    title: '视频标题',
                    fixed: 'left',
                    width: '200px',
                    dataIndex: 'videoTitle',
                    customRender:(text, row) => {
                        let txt = <a on-click={() => this.handlePreviewOpen(row.hjkAdVideoId)}>{ row.videoTitle }</a>
                        return row.videoStatus == 4 ? row.videoTitle : txt
                    }
                },
                {
                    align: 'center',
                    title: '视频品牌',
                    fixed: 'left',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '视频车系',
                    fixed: 'left',
                    dataIndex: 'carSeriesName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '集客场景',
                    fixed: 'left',
                    dataIndex: 'customerSceneDesc',
                    customRender(text) {
                        return text ||'-'
                    }
                },
                {
                    align: 'center',
                    title: '集客方式',
                    fixed: 'left',
                    dataIndex: 'customerTypeDescArr',
                    customRender(data) {
                        const strText = (data?.length && data.reduce((prev, item) => {
                            prev += `、${item}`;
                            return prev;
                        })) || "-"
                        return strText
                    }
                },
                {
                    align: 'center',
                    title: '视频有效期',
                    fixed: 'left',
                    dataIndex: 'videoValidDate',
                    customRender(text) {
                        return text ||'-'
                    }
                },
                {
                    align: 'center',
                    title: '操作',
                    fixed: 'left',
                    width: '150px',
                    dataIndex: 'operation',
                    scopedSlots: { customRender: 'operation' },
                },
                {
                    align: 'center',
                    title: '广告平台',
                    customRender: (text, row) => {
                        return row.adPlatformType == 1 ? '巨量广告' : row.adPlatformType == 2 ? '磁力广告' : row.adPlatformType == 3 ? '百度信息流' : '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender:(text, row) => {
                        return <div style="text-align: left;">
                                <div>{ row.advertiserName || '-' }</div>
                                <div class="txt">平台账套名：{row.adPlatformAccountName || '-'}</div>
                            </div>
                    }
                },
                {
                    align: 'center',
                    title: '广告主品牌',
                    dataIndex: 'advertiserPrincipalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '累计播放量',
                    sorter: true,
                    dataIndex: 'totalPlay',
                    customRender: (text, row) => {
                        return (row.totalPlay ?? '') !== '' ? utils.humanReadable(row.totalPlay, 2) : '-'
                    }
                },
                {
                    align: 'center',
                    title: '点击率',
                    sorter: true,
                    dataIndex: 'clickRate',
                    customRender: (text, row) => {
                        return (text ?? '') !== '' ? `${text}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '转化率',
                    sorter: true,
                    dataIndex: 'convertRate',
                    customRender: (text, row) => {
                        return (text ?? '') !== '' ? `${text}%` : '-'
                    }
                },
                {
                    align: 'center',
                    title: '上传进度',
                    dataIndex: 'uploadStatus',
                    customRender: (text, row) => {
                        return row.uploadStatus == 0 || row.uploadStatus == 1 || row.uploadStatus == 3 ? '未完成' : row.uploadStatus == 2 ? '已完成' : '-'
                    }
                },
            ],
            dataList: [],
            pagination: {
                current: 1,
                pageSize: 10,
                total: 0
            },
            selectedRowKeys: [],
            principalList: [],
            seriesList: [],
            customerSceneList: [],
            previewVisible: false,
            previewSrc: '',
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.videoStatus != 1 && record.videoStatus != 2,
                        // state: record.state
                    },
                })
            };
        },
    },
    created() {
        this.getPrincipalList()
        this.getPrincipalSeriesList()
        this.getCustomerScene()
        this.getDataList()
    },
    methods: {
        handlePreviewOpen(id) {
            this.$api.core.materialManage.getUrlByHjkAdVideoId(id).then((res) => {
                if(res.code == 200) {
                    // this.previewVisible = true
                    this.previewSrc = res.data.playUrl
                    window.open(res.data.playUrl, '_blank', 'noreferrer=yes')
                } else {
                    this.$message.error('获取视频地址失败')
                }
            })
        },
        handlePreviewCancel() {
            this.previewVisible = false
            this.previewSrc = ''
        },
        getPrincipalList() {
            this.$api.base_api.getPrincipalList().then(res => {
                if(res.code == 200) {
                    this.principalList = res.data
                } else {
                    this.principalList = []
                    console.error(`获取主体失败，${res}`)
                }
            })
        },
        onChangePrincipal(id) {
            this.getPrincipalSeriesList(id)
        },
        getPrincipalSeriesList(id) {
            let params = {
                principalIds: id,
                status: [1, 4]
            }
            this.$api.base_api.getPrincipalSeriesList(params).then(res => {
                if(res.code == 200) {
                    this.seriesList = res.data
                } else {
                    this.seriesList = []
                    console.error(`获取车系失败，${res}`)
                }
            })
        },
        getCustomerScene() {
            this.$api.core.materialManage.getCustomerScene().then(res => {
                if(res.code == 200) {
                    this.customerSceneList = res.data
                } else {
                    console.error(`获取集客场景失败，${res}`)
                }
            })
        },
        handleSearch() {
            this.pagination.current = 1
            this.getDataList()
        },
        handleReset() {
            this.form = {
                videoStatus: '1'
            }
            this.pagination.current = 1
            this.pagination.pageSize = 10
            this.getDataList()
            this.seriesList = []
            this.getPrincipalSeriesList()
        },
        handleTableChange(pagination, filters, sorter) {
            this.getDataList({
                sortList: [{
                    fieldName: sorter.columnKey,
                    orderType: sorter.order == 'ascend' ? 'asc' : sorter.order == 'descend' ? 'desc' : '',
                }]
            });
        },
        handlePaginationChange(current, pageSize) {
            this.pagination.current = current
            this.pagination.pageSize = pageSize
            this.getDataList()
        },
        getDataList(data = {}) {
            this.loading = true
            const _form = this.form
            let params = {
                ..._form,
                ...data,
                page: this.pagination.current,
                size: this.pagination.pageSize
            }
            this.$api.core.materialManage.getDeliveryVideoList(params).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.dataList = res.data.list || []
                    this.pagination.total = res.data.total || 0
                } else {
                    this.dataList = []
                    this.pagination.total = 0
                    this.$message.error(`获取列表失败，${res.message}`)
                }
            })
        },
        jumpDetailPage(id) {
            let routeUrl = this.$router.resolve({
                path: '/castVideoManage/detail',
                query: {
                    id
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        giftVideo(id) {
            this.$router.push({
                path: `/castVideoManage/giftVideo`,
                query: {
                    id,
                }
            })
        },
        editVideo(id, type) {
            let routeUrl = this.$router.resolve({
                path: '/castVideoManage/uploadVideo',
                query: {
                    id,
                    type
                }
            })
            window.open(routeUrl.href, '_blank');
        },
        //批量有效、停用
        batchByAdVideoIds(type) {
            if(this.selectedRowKeys.length < 1) {
                this.$message.error('请选择后再操作')
            } else {
                let params = {
                    type,
                    data: this.selectedRowKeys
                }
                
                this.$api.core.materialManage.batchByAdVideoIds(params).then((res) => {
                    this.selectedRowKeys = []
                    if(res.code == 200) {
                        this.getDataList()
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(`操作失败，${res.message}`)
                    }
                })
            }
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 40px;
}
.mt-40 {
    margin-top: 40px;
}
.txt {
    color: #ccc;
}
</style>